<!-- 错题集单科目 -->
<style lang="scss" scoped>
*{
    box-sizing: border-box;
}
.strict-courses{
    @include pageFoot;
    @include pageTitle(0);
    position: relative;
}
.page-title{
  padding: 20px 0;
}

::v-deep .el-popconfirm__action{
    margin-top: 15px !important;
}
.content{
    position: relative;
    height: calc(100vh - 280px);
    overflow: auto;
    .overdiv{
        background-color: #FFFFFF;
        padding: 20px;
        border-radius: 10px;
            .chartyuan{
                width: 200px;
                height: 200px;
                margin-right: 20px;
            }
			.dates{
				.item{
					width: 110px;
					text-align: center;
					.texttitle{
						width: 100%;
						height: 30px;
                        .iconfont{
                            font-size: 12px;
                            margin-right: 10px;
                        }
						.title{
							font-size: 24px;
                            font-weight: bold;
							color: #444444;
						}
						.text{
							color: #AAAAAA;
							font-size: 14px;
						}
					}
					.number{
						margin-top: 0px;
                        height: 30px;
                        font-size: 14px;
                        color: #222222;
                        line-height: 30px;
					}
				}
				.shu{
                    width: 1px;
                    height: 40px;
                    background-color: #D8D8D8;
                    margin: 0 15px;
				}
			}
    }
    .mistakes{
        overflow: hidden;
        margin-top: 20px;
        ::v-deep .el-tabs__header{
            line-height: 60px;
            background-color: #FFFFFF;
            padding: 0 20px 10px;
            margin-bottom: 0;
            border-radius: 10px;
        }
        .list{
            overflow: auto;
            position: relative;
            min-height: 300px;
            .item{
				width: 100%;
                background: #FDFDFD;
                border-radius: 10px;
                padding: 15px 20px 20px;
                position: relative;
                margin-top: 14px;
                overflow: hidden;
                &:hover{
                    cursor: pointer;
                    background-color: #F1F2F8;
                }
                
				.operate{
                    width: 70px;
                    height: 28px;
                    border-radius: 14px;
                    color: #FFFFFF;
                    z-index: 5;
                    margin-left: 6px;
				}
				.moldName{
					width: 90%;
					display: flex;
					.mold{
						padding: 0px 6px;
                        height: 26px;
                        background: rgba(1, 161, 253, 0.04);
                        border-radius: 6px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid #01A1FD;
                        color: #01A1FD;
                        font-size: 14px;
                        margin-right: 10px;
                        margin-top: 2px;
					}
					.name{
						font-size: 16px;
                        font-weight: 500;
                        color: #3D3D3D;
                        line-height: 26px;
                        padding-top: 1px;
                        flex: 1;
					}
				}
				.source{
                    height: 26px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #888888;
                    line-height: 26px;
                    margin-top: 6px;
				}
                .operation{
                    margin-top: 6px;
                }
				.p{
					font-size: 16px;
                    font-weight: 400;
                    color: #444444;
                    line-height: 26px;
                    margin-right: 20px;
				}
			}
        }
    }
}
::v-deep .el-dialog__title{
    &::before{
        display: none;
    }
    padding-left: 0px;
} 
.setNumber{
    text-align: center;
    .el-button{
        line-height: 40px;
        height: 40px;
        margin-top: 20px;
    }
} 
</style>

<template>
   <section class="strict-courses">
        
        <div class="page-title">
          <h3>{{course}}</h3>
          <div class="search-form">
            <el-form inline :model="form">
                <el-form-item>
                    <el-input
                    v-model.trim="form.keyword"
                    maxlength="100"
                    placeholder="请搜索题干"
                    clearable
                    />
                </el-form-item>
            </el-form>
            <el-button type="custom_primary" size="medium" @click="searchData">搜索</el-button>
          </div>
        </div>
        <div class="content">
			<div class="overdiv flex_content_center">
				<div class="chartyuan" ref="performance"></div>
				<div class="dates flex_content_center">
					<div class="item">
						<div class="texttitle flex_content_center">
							<!-- <div class="iconfont" style="color:#CCECFF">&#xe666;</div> -->
							<div class="title">{{count_num}}</div>
							<div class="text">(道)</div>
						</div>
						<div class="number">当前总数</div>
					</div>
					<div class="shu"></div>
					<div class="item">
						<div class="texttitle flex_content_center">
							<!-- <div class="iconfont" style="color:#01A1FD">&#xe666;</div> -->
							<div class="title">{{count_done}}</div>
							<div class="text">(道)</div>
						</div>
						<div class="number">已重做</div>
					</div>
					<div class="shu"></div>
					<div class="item">
						<div class="texttitle flex_content_center">
							<!-- <div class="iconfont" style="color:#CCECFF">&#xe666;</div> -->
							<div class="title">{{count_not}}</div>
							<div class="text">(道)</div>
						</div>
						<div class="number">剩余题</div>
					</div>
				</div>
			</div>
			<div class="mistakes">
                <el-tabs v-model="typeCurrent" @tab-click="handleClick">
                    <el-tab-pane label="全部" name="0"></el-tab-pane>
                    <el-tab-pane label="未做过" name="1"> </el-tab-pane>
                    <el-tab-pane label="已掌握" name="2"></el-tab-pane>
                    <el-tab-pane label="未掌握" name="3"></el-tab-pane>
                </el-tabs>
                <div class="list" v-loading="loading">
                    <div v-for="(item,index) in list" :key="index" class="item">
                        <div class="moldName">
                            <div class="mold">{{item.syque_typ_name}}</div>
                            <el-image style="width: 46px;height: 32px;margin:0 10px;" v-if="item.stque_image&&item.syque_typ_id!=99" fit="cover" :src="item.stque_image" :preview-src-list="[item.stque_image]" />
                            <div class="name clamp2">
                                <span class="richinline" v-html="item.stque_title"></span> 
                            </div>
                        </div>
                        <div class="source flex_align">
                            错题来源：<div class="c-orange">{{item.stque_source_title}}</div>
                        </div>
                        <div class="operation flex_bet_align">
                            <div class="flex_align">
                                <div class="p flex_align">答错次数：<div class="c-red">{{item.stque_errornum}}次</div></div>
                                <div class="p flex_align">答对次数：<div class="c-green">{{item.stque_correctnum}}次</div></div>
                            </div>
                           <div class="flex_align">
                                <el-button size="mini" type="primary" round slot="reference" @click="goDetails(item.stque_id)" class="operate">查看详情</el-button>
                                <el-popconfirm title="该题是否掌握？" confirm-button-text='已掌握' cancel-button-text='未掌握' @confirm="setGrasp(item.stque_id,index,30)"  @cancel="setGrasp(item.stque_id,index,20)">
                                    <el-button size="mini" type="success" round slot="reference" class="operate">掌握</el-button>
                                </el-popconfirm>
                           </div>
                        </div>
                    </div>
                    <div class="no-data--empty" v-if="$isEmpty(list)">
                        <img src="@assets/images/no-data3.png" alt="">
                        <p>暂无题</p>
                    </div>
                </div>
			</div>
		</div>
        <!-- 分页控件 -->
        <div class="custom-foot">
            <div class="combtn3" @click="showNumber=true">随机重做</div>
            <customPagination :current='tablePage.pageIndex' :total="tablePage.total" :size="tablePage.pageSize" @pageChange="flippingPage" />
        </div>
		<!-- 重做数量 -->
		<el-dialog title="设置重做数量" :visible.sync="showNumber" width="300px" center>
			<div class="setNumber">
                <el-input-number v-model="number" :min="1"  :max="count_num" label="请输入数量"></el-input-number>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="showNumber = false">取 消</el-button>
                    <el-button type="primary" @click="goDoExercise()">确 定</el-button>
                </div>
			</div>
        </el-dialog>
    </section>
</template>

<script>
import { stuquestionscschidList,is_grasp } from "@api/home"
import customPagination from "@comp/customPagination"
export default {
    name: 'mistakesDetails',
    components: { customPagination },
    data() {
        return {
            list: [], // 列表数据
            /* 表格分页参数 */
            tablePage: {
                pageIndex: 1,
                pageSize: 10,
                total: 0
            },
            loading:false,
            form: {
                keyword: "",
            },
            //课程id
            sysub_id:0,
            //班级id
            sccla_id:0,
            //课程中文
            course:"",
            //弹出重做数量输入
            showNumber:false,
            number:1,
            //选中类型下标
            typeCurrent:0,
            //页面数据
            orderInfo:{},
            //已重做
            count_done:0,
            //剩余题
            count_not:0,
            //当前总数
            count_num:0,
            //进度百分比
            percent:0,
        }
    },
    created() {
        this.sysub_id=this.$route.params.id
        this.sccla_id=this.$route.params.sccla_id
        this.course=this.$route.params.course
        this.getData();
    },
    methods: {
       
        /** 获取公开课列表数据 */
        async getData() {
            this.loading=true
            let params={
                pagesize:this.tablePage.pageSize,
                pageindex:this.tablePage.pageIndex,
                sysub_id:this.sysub_id,
                sccla_id:this.sccla_id,
                stque_title:this.form.keyword
            }
            if(this.typeCurrent>0){
                if(this.typeCurrent==3){
                    // 未掌握
                    params.stque_status=20
                }else if(this.typeCurrent==1){
                    // 未做过
                    params.stque_status=10
                }else if(this.typeCurrent==2){
                    // 已掌握
                    params.stque_status=30
                }
            }
            let { data } = await stuquestionscschidList(params);
            this.list = data.data;
            this.tablePage.total = data.allcount;
            this.count_done=data.count_done
            this.count_not=data.count_not
            this.count_num=data.count_num
            //获取百分比
            let percent=parseFloat(data.count_done/data.count_num).toFixed(2)
            this.percent=percent
            this.loading=false
            let titlename=(parseFloat(data.count_done/data.count_num)*100)
            this.drawChart((titlename%1>0?titlename.toFixed(2):titlename.toFixed(0)))
            // this.annulusOpts.title.name=(titlename%1>0?titlename.toFixed(2):titlename.toFixed(0))
            this.$forceUpdate();
        },
        /** 筛选数据 */
        searchData() {
            this.list = [];
            this.tablePage.pageIndex = 1;
            this.getData();
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
        //切换类型
        handleClick() {
            this.getData()
        },
        async setGrasp(id,index,tap){
            let params={
                stque_id:id,
                stque_status:tap,
            }
            let res = await is_grasp(params);
            if(res){
                if (tap==30){
                    if(this.typeCurrent==1||this.typeCurrent==3){
                        this.list.splice(index,1)
                    }
                }else{
                    if(this.typeCurrent==1||this.typeCurrent==2){
                        this.list.splice(index,1)
                    }
                }
            }
        },
        //跳转详情
        goDetails(id){
            this.$router.push({
                name:"MISTAKES_QUESTIONS",
                params: {
                  id:id
                }
            })
        },
        //做题
        goDoExercise() {
            this.$router.push({
                name:"MISTAKES_DOEXERCISE",
                params: {
                  id:this.sysub_id,
                  random_num:this.number,
				  sccla_id:this.sccla_id
                }
            })
        },
         /** 题目图表绘制 */
        drawChart(vals) {
            let echarts = this.$echarts.init(this.$refs.performance)
            let option = {
                title: {
                    text: vals+'%',
                    x: 'center',
                    y: 'center',
                    textStyle: {
                        fontWeight: 'normal',
                        color: '#01A1FD',
                        fontSize: '24'
                    }
                },
                color: ['rgba(1,161,253,.4)'],
                series: [{
                    type: 'pie',
                    radius: ['0', '66%'],
                    center: ['50%', '50%'],
                    hoverAnimation: false,
                    z: 0,
                    itemStyle: {
                        normal: {
                            color: '#FFFFFF',
                            label: {
                                show: false
                            },
                            labelLine: {
                                show: false
                            }
                        },
                    },
                    label: {
                        normal: {
                            position: "center",

                        }
                    },
                    data: [100],
                }, {
                    name: 'Line 1',
                    type: 'pie',
                    clockWise: true,
                    radius: ['70%', '90%'],
                    itemStyle: {
                        normal: {
                            label: {
                                show: false
                            },
                            labelLine: {
                                show: false
                            }
                        }
                    },
                    hoverAnimation: false,
                    data: [{
                        value: vals,
                        name: '01',
                        itemStyle: {
                            normal: {
                                color: { // 完成的圆环的颜色
                                    colorStops: [{
                                        offset: 1,
                                        color: '#01A1FD' // 100% 处的颜色
                                    }]
                                },
                                label: {
                                    show: false
                                },
                                labelLine: {
                                    show: false
                                }
                            }
                        }
                    }, {
                        name: '02',
                        value: 100-vals
                    }]
                }]
            };
            echarts.setOption(option)
        }
      
    },
}
</script>